<template>
  <article
    class="lyt-card lyt-card-basic"
    :class="{ active: active === uuid }"
    :data-uuid="uuid"
    @click.native="active = uuid"
  >
    <div class="image card-image" :class="{ active: active === uuid }">
      <slot name="image">
        <LinkTo class="img-link" :class="classes?.image" :to="path">
          <div class="relative">
            <Img class="w-full h-auto" :="img" />
            <PageBadge v-if="badge" class="card-badge" :badge="badge" />
          </div>
        </LinkTo>
      </slot>
    </div>

    <div class="card-body">
      <header class="heading" :class="classes?.heading">
        <slot name="heading">
          <h3
            class="title card-title text-3xl"
            :class="{ active: active === uuid }"
          >
            <LinkTo v-if="path" :to="path">{{ label }}</LinkTo>
            <span v-else>{{ label }}</span>
          </h3>
          <h4
            v-if="subtitles?.filter(Boolean).length"
            class="heading-subtitle mt-0"
          >
            <template v-for="(sub, idx) in subtitles">
              <div v-if="sub" :key="idx" :class="`subtitles sub-${idx + 1}`">
                {{ sub }}
              </div>
            </template>
          </h4>
        </slot>
      </header>
      <slot name="summary">
        <Wysiwyg
          v-if="body?.markup"
          class="summary my-2 line-clamp-3"
          :class="classes?.summary"
          :="body"
        />
      </slot>
    </div>
  </article>
</template>

<script lang="ts" setup>
import type { BasicCard } from '~/types'

type PropsType = Omit<BasicCard, 'variation'> & {
  classes?: {
    image?: string
    heading?: string
    summary?: string
  }
}
const props = defineProps<PropsType>()

const img = computed(
  () =>
    props.image || {
      height: 600,
      width: 600,
    },
)

const active = useState()
</script>

<style lang="postcss" scoped>
.active {
  .card-title {
    view-transition-name: title;
  }
  .card-image {
    view-transition-name: image;
    contain: layout;
  }
}

.card-badge {
  @apply absolute left-0 bottom-0;
}
</style>
